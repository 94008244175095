import React from "react"
import { graphql } from "gatsby"

import { SEO } from "../components/SEO"
import { DefaultLayout } from "../layouts/Default"
import { Hero } from "../components/Hero"
import { TestimonialSmall } from "../components/Testimonial"

const title = "Reviews of React Native School"

const calculateAverageRating = reviews => {
  const sum = reviews.reduce((acc, r) => {
    const rating = r.node?.rating || 5
    return acc + rating
  }, 0)
  const avg = Math.round((sum / reviews.length) * 10) / 10
  return avg
}

const filters = [
  { text: "All", value: undefined },
  { text: "★★★★★", value: 5 },
  { text: "★★★★", value: 4 },
  { text: "★★★", value: 3 },
  { text: "★★", value: 2 },
  { text: "★", value: 1 },
]

const Topics = ({ data }) => {
  const avg = calculateAverageRating(data.reviews.edges)
  const description = `Hear what your peers have to say. ${data.reviews.edges.length.toLocaleString()} reviews. ${avg}/5 rating.`

  const [filter, setFilter] = React.useState()

  const reviews = data.reviews.edges.filter(({ node }) => {
    if (!filter) return true
    return node.rating === filter
  })

  return (
    <DefaultLayout>
      <SEO title={title} description={description} />
      <Hero title={title} subtitle={description} />

      <div className="py-10">
        <section className="container mx-auto px-8 max-w-screen-xl flex flex-wrap">
          <span className="flex flex-row w-full justify-center mb-16">
            {filters.map(({ text, value }) => (
              <button
                type="button"
                className={`rounded-md px-2 py-1 mx-2 text-gray-800 hover:underline ${
                  value === filter
                    ? "bg-brand-green text-gray-50"
                    : "bg-gray-200"
                }`}
                onClick={() => setFilter(value)}
              >
                {text}
              </button>
            ))}
          </span>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-5">
            {reviews.map(({ node }) => (
              <TestimonialSmall
                key={node._id}
                text={node.text}
                author={node.name}
                course={node?.class?.title}
                rating={node.rating}
                date={node.date}
              />
            ))}
          </div>
        </section>
      </div>
    </DefaultLayout>
  )
}

export default Topics

export const query = graphql`
  query {
    reviews: allSanityReview(
      sort: { fields: date, order: DESC }
      filter: { text: { nin: ["", null] } }
    ) {
      edges {
        node {
          name
          text
          rating
          _id
          date
          class {
            title
          }
        }
      }
    }
  }
`
